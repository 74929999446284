/* 회원가입, 로그인 페이지의 input-box 크기와 위치 */
.signin-container {
  width: 50%;
  min-width: 450px;
  max-width: 600px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.login-input-container {
  width: 95%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin-top: 80px;
  padding-top: 50px;
  padding-bottom: 50px;

  background-color: rgb(246, 246, 246);
  border-radius: 12px;
}

.login-box {
  width: 70%;
  min-width: 340px;
  max-width: 450px;
}

.signin-button-container {
  width: 70%;
  min-width: 340px;
  max-width: 450px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
}

.signup-container {
  width: 60%;
  /* min-width: 340px; */
  /* max-width: 450px; */

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 60px;
  margin-top: 50px;
}

.signup-box-background {
  width: 70%;
  min-width: 490px;
  max-width: 560px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  background-color: rgb(246, 246, 246);
  border-radius: 12px;

  padding-top: 50px;
  padding-bottom: 50px;
}

.signup-box {
  width: 70%;
  min-width: 340px;
  max-width: 450px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.signup-box-top {
  margin-bottom: 30px;
}

.signup-box-top > .title {
  font-size: 25px;
  font-weight: bold;
}

.signup-box-middle {
  width: 100%;
}

.signup-box-bottom {
  min-width: 400px;
  width: 50%;
  max-width: 500px;

  margin-top: 40px;
}

.inputTitle {
  margin-top: 20px;
}

.inputWrap {
  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  min-width: 250px;
}

.inputWrap > input {
  border: 1.5px solid rgb(170, 170, 170);
  border-radius: 5px;
  font-size: 1rem;
  outline-style: none;

  width: 100%;
  height: 50px;

  padding: 13px 15px;
}

.link-container {
  width: 100%;
  height: 100%;

  margin-top: 10px;
  padding-top: 20px;

  display: flex;
  justify-content: space-between; /* 좌우 양끝 정렬 */
  align-items: center; /* 상하 중앙 정렬 */
}

.link-container a {
  color: black; /* a 태그의 색깔을 검정색으로 설정 */
}

.login-link {
  margin: 20px;
  text-align: center;

  font-size: 19px;
}

.login-link a {
  color: black; /* a 태그의 색깔을 검정색으로 설정 */
}

.errorMessageWrap > div {
  padding: 5px;
  font-size: 15px;
  color: red;
}

.please-box {
  font-size: 16px;
  color: black;

  padding-top: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center; /* 좌우 중앙 정렬 */
}

.signin-button-box {
  width: 100%;
  min-width: 250px;

  margin-top: 30px;
}

.signinButton {
  width: 100%;
  height: 50px;

  border-radius: 5px;
  border-style: none;

  background-color: black;
  transition: 0.3s;

  color: white;
  font-size: 20px;

  /* Shadow properties */
  box-shadow: 4px 4px 4px rgb(199, 198, 198);
}

.signin-button-box > .signinButton:hover {
  background-color: rgb(173, 173, 173);
  color: black;
  cursor: pointer;
}

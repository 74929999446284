/* 프로필 유저 정보 수정 페이지 - 컨테이너 */
.profile-update-container {
  width: 70%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
}

.update-user-info-page {
  min-width: 400px;
  width: 100%;
  max-width: 500px;

  min-height: 720px;
  height: 100%;
  max-height: 800px;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  margin-top: 30px;
  padding: 20px;
  padding-top: 40px;

  border-radius: 20px;

  /* Shadow properties */
  box-shadow: -1px -1px 1px rgba(218, 216, 216, 0.5),
    20px 20px 20px rgba(199, 198, 198, 0.7);
}
/* 프로필 유저 정보 수정 페이지 - 제목 */
.update-user-info-page-title {
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-user-info-page-title > span {
  font-size: 25px;
  font-weight: bold;
}

.user-info-input-container {
  width: 100%;
  height: 100%;
  /* 위-아래 배치, 정렬 */
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  /* 회원 정보 상단 여백 */
  margin-top: 20px;
}
/* 프로필 - 이미지  */
.profile-image-modal-container {
  width: 100%;
  height: 100%;
  max-height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 30px;
}
/* 프로필 - 이미지  */
.profile-image-wrapper {
  position: relative;
  width: 300px;
  height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/* 프로필 - 이미지  */
.profile-image {
  width: 300px;
  height: 300px;

  object-fit: cover;
  border-radius: 50%;

  padding: 5px;
  margin-top: 20px;

  border-color: #cbd5e0; /* equivalent to border-gray-400 */
}

/* 프로필 유저 정보 수정 페이지 - 편집 버튼 */
.change-photo-button {
  position: absolute;
  bottom: -1.2rem; /* 프로필 이미지 편집 버튼의 위아래 위치 조정 */

  left: 0;
  right: 0;

  margin: auto;
  width: fit-content;
  padding: 0.35rem;

  border-radius: 50%; /* equivalent to rounded-full */
  background-color: #ffffff; /* changed to white */

  border: 1px solid #4a5568; /* equivalent to border-gray-600 */
}

.change-photo-button:hover {
  background-color: #d9dbdf; /* light gray for hover effect */
}

/*  프로필 이미지 위의 수정버튼 박스 */
.user-info-container {
  margin-top: 10px;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;

  justify-content: center;
  align-items: center;
}

.profile-info-data-container {
  width: 100%;
  height: 50px;
}

.profile-update-info-container {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----------- 계정 활성화/비활성화 버튼 ----------- 휴대폰 인증, 이메일 인증 */
.account-activate {
  width: 83%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-top: 1px solid rgb(210, 210, 210); */
  /* border-bottom: 1px solid rgb(210, 210, 210); */
}

.account-activate > span {
  font-size: 16px;
  /* font-weight: bold; */
}

.account-activate > button {
  width: 75px;
  height: 32px;

  border-radius: 20px;
  border: 0.1px solid rgb(210, 210, 210);

  font-size: 15px;
  font-weight: bold;

  color: rgb(34, 34, 34);
  background-color: rgb(255, 255, 255);
}

.userinfo-checkbox {
  width: 83%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-checkbox {
  width: 83%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userinfo-checkbox > span {
  font-size: 16px;
  /* font-weight: bold; */
}

.userinfo-checkbox > button {
  width: 80px;
  height: 40px;

  border-radius: 20px;
  border: 0.1px solid rgb(210, 210, 210);
  font-size: 15px;
  font-weight: bold;

  color: rgb(34, 34, 34);
  background-color: rgb(255, 255, 255);

  margin-top: 10px;
}

.profile-info-confirm-btn {
  width: 50px;
  height: 40px;
  border-radius: 20px;
  border: 0.1px solid rgb(210, 210, 210);
  font-size: 16px;
  color: rgb(34, 34, 34);
  background-color: rgb(255, 255, 255);

  margin-top: 12px;
}

.profile-info-edit-btn {
  width: 45px;
  height: 32px;

  border-radius: 20px;
  border: 0.1px solid rgb(210, 210, 210);

  font-size: 15px;
  font-weight: bold;

  color: rgb(34, 34, 34);
  background-color: rgb(255, 255, 255);
}

.uui-btn-container {
  width: 35%;
  height: 90px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.uui-submit-btn {
  width: 150px;
  height: 40px;

  border-radius: 20px;
  border: none;

  font-size: 16px;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 34, 34);

  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* 프로필 수정 정보 - select box css */
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;

  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: "chaenil", sans-serif;

  color: #212529;
  background-color: #fff;
  background-clip: padding-box;

  border: 1px solid #ced4da;
  border-radius: 0.375rem;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  height: auto;
}

.form-select-sm {
  padding: 0.25rem 1.5rem 0.25rem 0.5rem;
  /* font-size: 0.875rem; */
  border-radius: 0.25rem;
}

/* 프로필 수정 페이지에서 input box 의 위치, 크기조절  */
.uii-container {
  margin-bottom: 10px;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.uii-cover {
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1.5px solid rgb(99, 99, 99);
  border-radius: 8px;

  margin-top: 1px;
  padding: 5px;
}

.uii-cover > input {
  width: 100%;
  height: 100%;
  border-width: 0px;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
}

.uii-cover > span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-width: 0px;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
}

.uii-address {
  margin-bottom: 15px;
}

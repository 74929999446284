.management-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(35, 35, 35, 0.8);
    overflow-y: auto;
}

.management-modal-container {
    width: 60%;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
}

.management-modal-container.confirm-modal-active {
    filter: blur(3px) brightness(0.3);
}

.management-modal-top {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 60px 0px 60px;
}

.update-product-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
}

.update-product-img-info {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
}

.close-management-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 25px;
}

.management-modal-input-container {
    width: 100%;
    padding: 0px 60px 60px 60px;
}

.confirm-modal {
    width: 30%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0, 0, 0 / 7%) 0px 0px 6px 3px;
    border-radius: 8px;
    padding: 15px;
}

.confirm-modal-content {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
}

.confirm-modal-content>p {
    font-size: 21px;
}

.confirm-modal-content>button {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1.5px solid rgb(210, 210, 210);
    outline: none;
    font-size: 16px;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}
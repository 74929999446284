.footer {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px auto;
    color: rgb(255, 255, 255);
}

.footer-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.section {
    height: 300px;
    padding: 30px;
}

.section1 {
    width: 40%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.section1>a {
    font-size: 18px;
    color: rgb(130, 130, 130);
    margin-top: 15px;
}

.section2 {
    width: 60%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.footer-box2 {
    height: 70%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: rgb(130, 130, 130);
}

.footer-box2>a {
    font-size: 18px;
    color: rgb(130, 130, 130);
    border-bottom: 0.5px solid rgb(130, 130, 130);
    padding-bottom: 10px;
    margin-top: 15px;
}

.footer-right {
    width: 580px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    box-shadow: inset 10px 0 10px rgba(0, 0, 0, 0.5);
}

.section3 {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translateY(-50%);
    background-color: rgb(255, 255, 255);
    transition: left 0.3s ease-in-out;
    z-index: 500;
    padding: 50px;
}

.member-card-active {
    left: 0;
}

.member-card-top {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.member-card-logo {
    width: 180px;
}

.member-card-contents {
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
}

.mc-name-position {
    width: 35%;
    height: 50px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: bold;
    color: rgb(34, 34, 34);
    margin-top: 10px;
}

.mc-name-position>span:first-child {
    font-size: 25px;
}

.mc-name-position>span:last-child {
    font-size: 18px;
    color: rgb(130, 130, 130);
}

.mc-border-line {
    height: 100%;
    border: 0.5px solid rgb(210, 210, 210);
}

.mc-contact-info {
    width: 65%;
    height: 50px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 17px;
    font-weight: bold;
    color: rgb(130, 130, 130);
    margin-top: 10px;
    padding-left: 30px;
}

.mc-contact-info>span>a {
    color: rgb(130, 130, 130);
}

.mc-contact-info>span>a:hover {
    color: rgb(34, 34, 34);
}

.floating-message {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: normal;
    color: rgb(130, 130, 130);
}

@media (max-width: 1200px) {
    .footer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0px auto;
        color: rgb(255, 255, 255);
    }

    .footer-left {
        width: 90%;
        height: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 15px;
    }
    
    .section {
        height: 250px;
        padding: 10px;
    }
    
    .section1 {
        width: 100px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .section1>a {
        font-size: 18px;
        color: rgb(130, 130, 130);
        margin-top: 15px;
    }
    
    .section2 {
        width: 230px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .footer-box2 {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: rgb(130, 130, 130);
    }
    
    .footer-box2>a {
        font-size: 18px;
        color: rgb(130, 130, 130);
        border-bottom: 0.5px solid rgb(130, 130, 130);
        padding-bottom: 10px;
        margin-top: 15px;
    }
    
    .footer-right {
        width: 100%;
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        margin: 10px 0px;
        box-shadow: inset 0 10px 10px rgba(0, 0, 0, 0.5);
    }
    
    .section3 {
        width: 95%;
        height: 240px;
        position: absolute;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgb(255, 255, 255);
        transition: top 0.3s ease-in-out;
        z-index: 500;
        padding: 20px;
    }
    
    .member-card-active {
        top: 0;
    }
    
    .member-card-top {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .member-card-logo {
        width: 120px;
    }
    
    .member-card-contents {
        width: 100%;
        height: 120px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 20px;
    }
    
    .mc-name-position {
        width: 20%;
        height: 50px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: bold;
        color: rgb(34, 34, 34);
        margin-top: 10px;
    }
    
    .mc-name-position>span:first-child {
        font-size: 25px;
    }
    
    .mc-name-position>span:last-child {
        font-size: 18px;
        color: rgb(130, 130, 130);
    }
    
    .mc-border-line {
        height: 100%;
        border: 0.5px solid rgb(210, 210, 210);
    }
    
    .mc-contact-info {
        width: 75%;
        height: 50px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 17px;
        font-weight: bold;
        color: rgb(130, 130, 130);
        margin-top: 10px;
        padding-left: 15px;
    }
    
    .mc-contact-info>span>a {
        color: rgb(130, 130, 130);
    }
    
    .mc-contact-info>span>a:hover {
        color: rgb(34, 34, 34);
    }
    
    .floating-message {
        width: 250px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: normal;
        color: rgb(130, 130, 130);
    }
}
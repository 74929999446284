.pd-page-heart-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    color: rgb(34, 34, 34);
    border: 1.5px solid rgb(90, 90, 90);
    border-radius: 8px;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
}

.pd-page-heart-btn>svg {
    margin-right: 10px;
}

.cart-page-heart-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    cursor: pointer;
}

.article-view-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgb(255, 255, 255);
  overflow-y: auto;
}

.article-view-modal.ram-open {
  filter: blur(1px) brightness(0.7);
}

.article-view-modal.iam-open {
  filter: blur(1px) brightness(0.7);
}

.product-info-section {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  padding: 25px;
  background-color: rgb(255, 255, 255);
}

.txt-info {
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.img-info {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 25px;
}

.rating-and-recommendations-section {
  width: 70%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.rating-and-recommendations-section > .star-rate {
  font-size: 40px;
  font-weight: bold;
}

.rating-and-recommendations-section > .review-count {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 15px;
}

.rating-and-recommendations-section > .rate-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.rating-and-recommendations-section > .rate-box span {
  font-size: 0.9rem;
  font-weight: bold;
}

.pagination-info-section {
  width: 70%;
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 60px;
}

.review-filter-section {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1.5px solid rgb(210, 210, 210);
}

.review-sort-box {
  width: 20%;
  position: relative;
}

.selected-sort-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.review-sort-option {
  width: 70%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.review-sort-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-filter-box {
  width: 20%;
  position: relative;
}

.selected-filter-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.review-filter-option {
  width: 70%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.review-filter-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-section {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1.5px solid rgb(210, 210, 210);
  margin-top: 30px;
  padding-bottom: 15px;
}

.review-section > img {
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
}

.modal-review-box {
  width: 88%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-review-box.admin-review-box {
  width: 65%;
}

.star-rate {
  margin-bottom: 5px;
}

.writer-date-info-box {
  font-size: 13px;
  color: rgb(150, 150, 150);
  margin-top: 30px;
}

.article-answer-btn-container {
  width: 20%;
  height: 40px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
}

.article-answer-btn {
  width: 100px;
  height: 35px;
  border-radius: 25px;
  border: none;
  font-size: 14px;
  color: rgb(255, 255, 255);
  background-color: rgb(34, 34, 34);
  cursor: pointer;
}

.review-answer-modal {
  width: 660px;
  height: 390px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
  z-index: 2000;
  padding: 40px 60px;
}

.review-answer-modal.ram-open {
  visibility: visible;
  opacity: 1;
}

.modal-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.modal-title > span {
  font-size: 30px;
}

.ram-input-cover {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid rgb(99, 99, 99);
  border-radius: 8px;
  padding: 10px;
}

.ram-input-cover > textarea {
  width: 100%;
  height: 100%;
  border-width: 0px;
  border-radius: 8px;
  outline: none;
  resize: none;
  font-size: 16px;
}

.review-answer-modal > .submit-btn {
  margin-top: 40px;
}

.inquiry-title-section {
  width: 70%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 100px;
}

.inquiry-title-section > .inquiry-count {
  /* font-size: 1.25rem; */
  font-size: 40px;
  font-weight: bold;
}

.inquiry-title-section > .count-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
}

.inquiry-title-section > .count-box span {
  font-size: 0.9rem;
  font-weight: bold;
}

.inquiry-filter-section {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1.5px solid rgb(210, 210, 210);
}

.inquiry-filter-section > .inquiry-sort-box {
  width: 20%;
  position: relative;
}

.inquiry-filter-section > .inquiry-sort-box > .selected-sort-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.inquiry-filter-section > .inquiry-sort-box > .inquiry-sort-option {
  width: 70%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.inquiry-filter-section > .inquiry-sort-box > .inquiry-sort-option.drop-option {
  display: flex;
}

.inquiry-filter-section > .inquiry-sort-box > .inquiry-sort-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inquiry-filter-section > .inquiry-filter-box {
  width: 20%;
  position: relative;
}

.inquiry-filter-section > .inquiry-filter-box > .selected-filter-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.inquiry-filter-section > .inquiry-filter-box > .inquiry-filter-option {
  width: 75%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.inquiry-filter-section > .inquiry-filter-box > .inquiry-filter-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.inquiry-filter-section
  > .inquiry-filter-box
  > .inquiry-filter-option.drop-option {
  display: flex;
}

.inquiry-section {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1.5px solid rgb(210, 210, 210);
  cursor: pointer;
  margin-top: 30px;
  padding-bottom: 10px;
}

.inquiry-num-and-category-box {
  width: 20%;
}

.inquiry-content-box {
  width: 65%;
}

.inquiry-section > .writer-info-box {
  width: 10%;
}

.inquiry-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}

.inquiry-answer-modal {
  width: 660px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
  z-index: 2000;
  overflow-y: auto;
  padding: 40px 60px;
}

.inquiry-answer-modal.iam-open {
  visibility: visible;
  opacity: 1;
}

.iam-detail-content {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
}

.iam-detail-content > div {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 60px;
}

.iam-detail-content > div > span:first-child {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 7px;
}

.iam-detail-content > div > span:last-child {
  font-size: 17px;
}

.iam-detail-content > .iam-detail-content-wrapper {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.iam-detail-content-wrapper > div {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.iam-detail-content-wrapper > div > span:first-child {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 7px;
}

.iam-detail-content-wrapper > div > span:last-child {
  font-size: 17px;
}

.iam-input-title {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 7px;
}

.iam-input-cover {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid rgb(99, 99, 99);
  border-radius: 8px;
  padding: 10px;
}

.iam-input-cover > textarea {
  width: 100%;
  height: 100%;
  border-width: 0px;
  border-radius: 8px;
  outline: none;
  resize: none;
  font-size: 16px;
}

.inquiry-answer-modal > .submit-btn {
  margin-top: 40px;
}

.review-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  position: relative; /* 추가: 자식 요소의 절대 위치를 기준으로 삼음 */
  overflow: visible; /* 추가: 자식 요소가 부모의 경계를 벗어나도록 허용 */
}

.review-page-title-container {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.review-page-title {
  font-size: 24px;
  font-weight: bold;
}

.total-review-count {
  font-size: 20px;
}

.review-page-filter {
  min-width: 700px;
  width: 70%;
  max-width: 800px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1.5px solid rgb(210, 210, 210);
}

.review-sort-box {
  width: 20%;
  position: relative;
}

.selected-sort-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.review-sort-option {
  width: 70%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.review-sort-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-filter-box {
  width: 20%;
  position: relative;
}

.selected-filter-option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
}

.review-filter-option {
  width: 70%;
  display: none;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  top: 50px;
  background-color: rgb(255, 255, 255);
  padding: 10px 0px 10px 20px;
  box-shadow: rgb(0 0 0 / 7%) 0px 0px 6px 3px;
}

.review-filter-option > li {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-container {
  min-width: 700px;
  width: 70%;
  max-width: 800px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  border-bottom: 1.5px solid rgb(210, 210, 210);
  margin-top: 30px;
  padding-bottom: 30px;
}

.review-images-wrapper {
  width: 100%;
  max-width: 480px;
  height: 135px;

  position: relative; /* 추가: 자식 요소의 절대 위치를 기준으로 삼음 */

  padding-top: 15px;
  padding-left: 5px;
  padding-right: 10px;

  overflow: visible; /* 내부 요소가 경계를 벗어나도록 허용 */
}

.review-images {
  width: 100%;
  max-width: 480px;
  height: 135px;

  gap: 6px;

  display: flex;
  overflow-x: auto; /* 가로 스크롤 활성화 */
  white-space: nowrap; /* 요소들이 한 줄로 배치되도록 설정 */
  -webkit-overflow-scrolling: touch; /* 부드러운 스크롤 */
  /* overflow: visible; */
}

.review-image-container {
  height: 120px;
  width: 100px;

  position: relative; /* 미리보기 이미지를 위한 상대 위치 설정 */

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 5;
}

.swal-content img {
  max-width: 450px; /* 가로 최대 길이 450px */
  max-height: 600px; /* 세로 최대 길이 450px */
  width: 100%; /* 가로가 450px 이하일 때 100%로 맞춤 */
  height: auto; /* 비율을 유지한 채로 세로 길이 자동 조정 */
  object-fit: contain; /* 이미지가 창에 맞추어져 보이도록 함 */
}

.review-image {
  height: 100px;
  width: 100px;
  object-fit: cover;

  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  cursor: pointer;
}

.review-images::-webkit-scrollbar {
  height: 8px; /* 스크롤바의 높이 설정 */
}

.review-images::-webkit-scrollbar-thumb {
  background: #888; /* 스크롤바의 색상 설정 */
  border-radius: 4px; /* 스크롤바의 모서리 둥글기 설정 */
}

.review-images::-webkit-scrollbar-thumb:hover {
  background: #555; /* 스크롤바의 hover 색상 설정 */
}

.preview-image {
  display: none;
  position: absolute;

  bottom: 120%; /* 미리보기 이미지 위치 조정 */
  left: 50%;
  transform: translateX(-50%);

  width: 240px; /* 미리보기 이미지 크기 */
  height: auto;

  z-index: 5; /* z-index를 높게 설정 */
  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.review-image-container:hover .preview-image {
  display: block;
}

.review-product {
  width: 20%;
  max-width: 130px;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  background-color: rgb(255, 255, 255);
  margin-right: 40px;

  position: relative; /* 추가: 미리보기 이미지를 절대 위치로 배치할 수 있도록 함 */
}

.review-product-img {
  width: 120px;
  height: 120px;

  object-fit: cover;
  object-position: center;

  margin-right: 10px;
  margin-bottom: 15px;

  border: 1px solid rgb(224, 224, 224);
  border-radius: 8px;
}

.review-main-content-container {
  width: 60%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: visible;
}

.review-main-content-container > .rating-box {
  margin-bottom: 5px;
}

.review-main-content-container > img {
  width: 90px;
  height: 90px;

  object-fit: cover;
  object-position: center;

  margin-top: 15px;
}

.review-edit-del-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  padding-left: 10px;
  z-index: 1;
}

.review-edit-del-container > .review-date {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 15px;
}

.review-edit-del {
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: center;
}

.review-edit-del > button {
  width: 100%;
  height: 40px;

  border: 1.5px solid rgb(210, 210, 210);
  border-radius: 8px;
  outline: none;
  font-size: 15px;

  background-color: rgb(34, 34, 34);
  color: rgb(255, 255, 255);

  cursor: pointer;
  margin-bottom: 5px;

  z-index: 6;
}

/* ------- Modal.jsx 에 정의된 클래스 ------- */
.modal-container {
  position: relative;
  z-index: 10;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(17, 24, 39, 0.75);
  backdrop-filter: blur(10px); /* equivalent to backdrop-blur-sm */
  transition: all 0.3s;

  z-index: 12;

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  position: fixed;
  inset: 0;
  z-index: 101;
  width: 100vw;
  overflow-y: auto;
}

.modal-content-wrapper {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  padding: 3rem 0.5rem; /* equivalent to px-2 py-12 */
  text-align: center;
}

.modal-content {
  position: relative;
  width: 95%;
  max-width: 80%;
  min-height: 60vh;

  border-radius: 1rem;
  background-color: #ffffff;
  color: #f1f5f9;

  text-align: left;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); /* equivalent to shadow-xl */
  transition: all 0.3s;

  z-index: 101;
}

.modal-header {
  padding: 1.25rem; /* equivalent to px-5 py-4 */
}

.close-button {
  border-radius: 0.375rem; /* equivalent to rounded-md */
  padding: 0.25rem; /* equivalent to p-1 */
  display: inline-flex;
  align-items: center;
  justify-content: center;

  color: #000000; /* equivalent to text-gray-400 */
  background-color: transparent;

  position: absolute;
  top: 0.5rem; /* equivalent to top-2 */
  right: 0.5rem; /* equivalent to right-2 */
}

.close-button:hover {
  background-color: #dfdfdf; /* equivalent to hover:bg-gray-700 */
}

.close-button:focus {
  outline: none; /* equivalent to focus:outline-none */
}

/* ------- ImageCropper.jsx 에 정의된 클래스 ------- */
.file-input-container {
  display: block;
  margin-bottom: 0.75rem; /* equivalent to mb-3 */
  width: fit-content;
}

.file-input {
  display: block;
  width: 100%;

  font-size: 0.875rem; /* equivalent to text-sm */
  color: #94a3b8; /* equivalent to text-slate-500 */
}

.file-input::-webkit-file-upload-button {
  margin-right: 1rem; /* equivalent to file:mr-4 */
  padding: 0.25rem 0.5rem; /* equivalent to file:py-1 file:px-2 */
  border-radius: 9999px; /* equivalent to file:rounded-full */
  border: none; /* equivalent to file:border-0 */
  font-size: 0.75rem; /* equivalent to file:text-xs */
  background-color: #000000;
  color: #ffffff;
}

.file-input::-webkit-file-upload-button:hover {
  background-color: #202429; /* equivalent to hover:file:bg-gray-600 */
}

.error-message {
  color: #f87171; /* equivalent to text-red-400 */
  font-size: 0.75rem; /* equivalent to text-xs */
}

.cropper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crop-button {
  font-family: monospace; /* equivalent to font-mono */
  font-size: 0.75rem; /* equivalent to text-xs */
  padding: 0.5rem 1rem; /* equivalent to py-2 px-4 */
  border-radius: 1rem; /* equivalent to rounded-2xl */
  margin-top: 1rem; /* equivalent to mt-4 */
  background-color: #000000;
  color: #ffffff;
}

.crop-button:hover {
  color: #000000;
  background-color: #ffffff;
}

.preview-canvas {
  margin-top: 1rem; /* equivalent to mt-4 */
  display: none;
  border: 1px solid black;
  object-fit: contain;
  width: 150px;
  height: 150px;
}

/* ------- PencilIcon.jsx 에 정의된 클래스 ------- */
.pencil-icon {
  width: 1rem; /* equivalent to w-4 */
  height: 1rem; /* equivalent to h-4 */
}

.cart-page {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.cart-page-wrapper {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.cart {
    width: 60%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.cart-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.page-title {
    font-size: 24px;
    font-weight: bold;
}

.cart-middle {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.cart-img {
    width: 164px;
    height: 164px;
    object-fit: cover;
    object-position: center;
}

.cart-option-info {
    width: 50%;
    height: 164px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 15px;
}

.cart-product-name {
    height: 20%;
    font-size: 18px;
    font-weight: bold;
}

.cart-product-type,
.cart-product-color {
    height: 15%;
    color: rgb(150, 150, 150);
}

.cart-product-size-quantity {
    height: 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: rgb(150, 150, 150);
}

.size {
    margin-right: 15px;
}

.size>span {
    margin-right: 5px;
}

.quantity {
    height: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantity>span {
    margin-right: 5px;
}

.quantity-input-cart {
    width: 65px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1.5px solid rgb(210, 210, 210);
    border-radius: 8px;
}

.quantity-input-cart>span {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-flex-cart {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.btn-flex-cart>svg {
    width: 30px;
    height: 50%;
}

.btn-flex-cart>svg:hover {
    cursor: pointer;
}

.cart-btn-container {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.cart-btn-container>svg {
    font-size: 25px;
    cursor: pointer;
}

.cart-btn-container>svg:nth-child(2) {
    margin-left: 15px;
}

.cart-product-price {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.bill {
    width: 25%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 60px;
}

.bill-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bill-middle {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0px;
}

.bill-product-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.bill-delivery-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding-bottom: 15px;
}

.bill-total-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 15px 0px;
}

.bill-bottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
}

.order-btn {
    width: 100%;
    height: 60px;
    font-size: 17px;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
    border-radius: 8px;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .cart-page {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
    }
    
    .cart-page-wrapper {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
    }
    
    .cart {
        width: 60%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .cart-top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .page-title {
        font-size: 24px;
        font-weight: bold;
    }
    
    .cart-middle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding: 30px 0px;
    }
    
    .cart-img {
        width: 164px;
        height: 164px;
        object-fit: cover;
        object-position: center;
    }
    
    .cart-option-info {
        width: 50%;
        height: 164px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 15px;
    }
    
    .cart-product-name {
        height: 20%;
        font-size: 18px;
        font-weight: bold;
    }
    
    .cart-product-type,
    .cart-product-color {
        height: 15%;
        color: rgb(150, 150, 150);
    }
    
    .cart-product-size-quantity {
        height: 15%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: rgb(150, 150, 150);
    }
    
    .size {
        margin-right: 15px;
    }
    
    .size>span {
        margin-right: 5px;
    }
    
    .quantity {
        height: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .quantity>span {
        margin-right: 5px;
    }
    
    .quantity-input-cart {
        width: 65px;
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
    }
    
    .quantity-input-cart>span {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex-cart {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
    .btn-flex-cart>svg {
        width: 30px;
        height: 50%;
    }
    
    .btn-flex-cart>svg:hover {
        cursor: pointer;
    }
    
    .cart-btn-container {
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
    
    .cart-btn-container>svg {
        font-size: 25px;
        cursor: pointer;
    }
    
    .cart-btn-container>svg:nth-child(2) {
        margin-left: 15px;
    }
    
    .cart-product-price {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }
    
    .bill {
        width: 25%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: sticky;
        top: 60px;
    }
    
    .bill-top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .bill-middle {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30px 0px;
    }
    
    .bill-product-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .bill-delivery-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding-bottom: 15px;
    }
    
    .bill-total-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding: 15px 0px;
    }
    
    .bill-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .order-btn {
        width: 100%;
        height: 60px;
        font-size: 17px;
        font-weight: bold;
        color: rgb(255, 255, 255);
        background-color: rgb(34, 34, 34);
        border-radius: 8px;
        cursor: pointer;
    }
}

@media (max-width: 900px) {
    .cart-page {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
    }
    
    .cart-page-wrapper {
        width: 90%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    
    .cart {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .cart-top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .page-title {
        font-size: 24px;
        font-weight: bold;
    }
    
    .cart-middle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding: 30px 0px;
    }
    
    .cart-img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: center;
    }
    
    .cart-option-info {
        width: 50%;
        height: 164px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 15px;
    }
    
    .cart-product-name {
        height: 20%;
        font-size: 18px;
        font-weight: bold;
    }
    
    .cart-product-type,
    .cart-product-color {
        height: 15%;
        color: rgb(150, 150, 150);
    }
    
    .cart-product-size-quantity {
        height: 15%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: rgb(150, 150, 150);
    }
    
    .size {
        margin-right: 15px;
    }
    
    .size>span {
        margin-right: 5px;
    }
    
    .quantity {
        height: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .quantity>span {
        margin-right: 5px;
    }
    
    .quantity-input-cart {
        width: 65px;
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
    }
    
    .quantity-input-cart>span {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex-cart {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    
    .btn-flex-cart>svg {
        width: 30px;
        height: 50%;
    }
    
    .btn-flex-cart>svg:hover {
        cursor: pointer;
    }
    
    .cart-btn-container {
        width: 100%;
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
    
    .cart-btn-container>svg {
        font-size: 25px;
        cursor: pointer;
    }
    
    .cart-btn-container>svg:nth-child(2) {
        margin-left: 15px;
    }
    
    .cart-product-price {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
    }
    
    .bill {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        position: static;
        top: 0;
        margin-top: 60px;
    }
    
    .bill-top {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    .bill-middle {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30px 0px;
    }
    
    .bill-product-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .bill-delivery-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding-bottom: 15px;
    }
    
    .bill-total-price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
        padding: 15px 0px;
    }
    
    .bill-bottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;
    }
    
    .order-btn {
        width: 100%;
        height: 60px;
        font-size: 17px;
        font-weight: bold;
        color: rgb(255, 255, 255);
        background-color: rgb(34, 34, 34);
        border-radius: 8px;
        cursor: pointer;
    }
}
.administrator-page {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.input-section {
    margin-bottom: 60px;
}

.admin-page-navigator {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-page-title {
    width: 240px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.admin-page-title>span {
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

.admin-page-filter-btn {
    width: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.admin-page-contents {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
}

.admin-menu-filter-container {
    width: 0;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
}

.admin-menu-btn-container {
    width: 220px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-menu-btn-container>button {
    height: 35px;
    border-radius: 25px;
    border: none;
    font-size: 14px;
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
    cursor: pointer;
}

.admin-menu-btn-container>button:nth-child(1) {
    width: 90px;
}
.admin-menu-btn-container>button:nth-child(2) {
    width: 120px;
}

.selected-admin-menu {
    font-weight: bold;
}

.admin-menu-filter-active {
    width: 260px;
    opacity: 1;
    visibility: visible;
}
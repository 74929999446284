@font-face {
  font-family: 'chaenii';
  src: url('../public/fonts/chaenii.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'chaenii', sans-serif;
}

.Top-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Middle-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 180px;
}

.Middle-section.scroll {
  margin-top: 60px;
}

.blur {
  background: rgba(255, 255, 255, 0.8);
  filter: blur(1px) brightness(0.5);
}

.Bottom-section {
  height: 360px;
  background-color: rgba(34, 34, 34, 1);
  margin-bottom: 30px;
}

.swal-title:not(:last-child) {
  font-size: 23px;
  margin-bottom: 30px;
}

.swal-text {
  font-size: 21px;
  margin-bottom: 30px;
}

.swal-footer {
  display: flex;
  justify-content: center;
  background-color: rgb(240, 240, 240);
  overflow: hidden;
}

.swal-button {
  background-color: rgb(34, 34, 34);
  color: rgb(255, 255, 255);
}

.swal-button:not([disabled]):hover {
  background-color: rgb(34, 34, 34);
}

@media (max-width: 1200px) {
  .Bottom-section {
    width: 100%;
    height: 510px;
    background-color: rgba(34, 34, 34, 1);
    margin-bottom: 30px;
  }
}
:root {
  --profile-background-color: rgb(0, 0, 0);
  --profile-text-color: rgb(255, 255, 255);
  --secondary-text-color: rgb(150, 150, 150);
  --border-color: rgb(210, 210, 210);
  --button-bg-color: rgb(255, 255, 255);
  --button-text-color: rgb(34, 34, 34);
  --image-bg-color: rgb(210, 210, 210);
}

.my-profile-page {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  /* background-color: rgb(243, 243, 243); */
  background-color: var(--profile-background-color);

  padding: 10px;
}

.my-profile-page-title {
  width: 68%;
  height: 35px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  color: var(--profile-text-color);
}

.my-profile-page-title > span {
  font-size: 20px;
  font-weight: bold;
}

.short-my-profile {
  width: 100%;
  height: 100%;
  max-height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-container {
  min-width: 400px;
  width: 68%;
  max-width: 850px;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.profile-image-container {
  width: 170px;
  height: 150px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-radius: 5px;
}

.name-joined-container {
  min-width: 210px;
  max-width: 300px;

  height: 100%;
  max-height: 150px;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;

  padding: 10px;
}

.profile-image-container img {
  width: 150px;
  height: 150px;

  object-fit: cover;
  border-radius: 50%;

  padding: 5px;

  border-color: #cbd5e0; /* equivalent to border-gray-400 */
}

.user-joined-date-info {
  font-size: 16px;
}

.name-joined-container > a {
  font-size: 16px;
  color: rgb(150, 150, 150);
}

/* 프로필 정보 시작 */
.larze-font-size-and-weight {
  max-width: 270px;

  font-size: 36px;
  font-weight: bold;

  overflow-x: auto; /* 가로 스크롤 활성화 */
  white-space: nowrap; /* 텍스트 줄 바꿈 방지 */
}

.middle-font-size-and-weight {
  font-size: 24px;
  font-weight: bold;
}

.small-font-size-and-weight {
  font-size: 14px;
  font-weight: bold;
}

.ml-5 {
  margin-left: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-5 > a {
  color: var(--profile-text-color);
}

.profile-info-container {
  width: 100%;
  height: 100%;
  min-height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-navi-page {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-navi-page a {
  font-size: 19px;
}

.profile-info {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* border-top: 1px solid rgb(210, 210, 210); */
  /* border-bottom: 1px solid rgb(210, 210, 210); */
}

.profile-info > span {
  color: var(--profile-text-color);
}

.profile-info > button {
  width: 80px;
  height: 40px;
  border-radius: 20px;
  border: 0.1px solid rgb(210, 210, 210);
  font-size: 16px;
  color: rgb(34, 34, 34);
  background-color: rgb(255, 255, 255);
}
/* 프로필 정보 끝 */

/* 마이 페이지 각 링크에 대한 페이지 이동 */
.my-page-links-list-container {
  min-width: 300px;
  width: 50%;
  max-width: 375px;
  max-height: 150px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  padding-left: 50px;
}

@media (max-width: 700px) {
  .my-page-links-list-container {
    display: none;
  }
}

.my-page-links-card {
  width: 25%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.my-page-link {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.my-page-link > img {
  width: 45px;
  height: 45px;

  margin-bottom: 5px;
}

.my-page-link > span {
  height: 20px;
  color: white;
}

.short-payment-history,
.short-wish-list,
.short-my-review {
  width: 100%;
  height: 490px;
  margin-top: 60px;
}

.payhis-product-info-zero-data {
  margin-top: 50px;

  font-size: 15px;
}

.sph-title,
.swl-title,
.smr-title {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sph-title > span:first-child,
.swl-title > span:first-child,
.smr-title > span:first-child {
  font-size: 25px;
  font-weight: bold;
}

.sph-title > span:last-child > a,
.swl-title > span:last-child > a,
.smr-title > span:last-child > a {
  color: rgb(150, 150, 150);
  cursor: pointer;
}

.sph-card-container {
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.sph-card {
  width: 360px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.sph-card > img {
  width: 360px;
  height: 360px;
  background-color: rgb(210, 210, 210);
}

.sph-card-text {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.swl-card-container {
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  gap: 40px;
}

.smr-card-container {
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.left-section,
.right-section {
  float: left;
}

/* React Daum Address API */
.postcode-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 12;
}

.postcode-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;

  width: 100%;
  max-width: 450px;

  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.postcode-modal-body {
  margin-top: 10px;
  max-height: 520px;

  width: 100%;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  padding-top: 40px;
}

.close-button {
  position: absolute; /* Position it absolutely */
  top: 0px;
  right: 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1; /* Ensure it stays on top */
}

.close-button button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 1px; /* Remove padding */
  cursor: pointer; /* Ensure pointer cursor */
}

.close-button img {
  width: 20px;
  height: 20px;
}
/* React Daum Address API - 끝 */

.address-registration-page {
  width: 70%;
  padding: 20px;
}

.address-registration-page h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.address-registration-page p {
  margin-bottom: 20px;
}
.address-registration-form {
  width: 70%;
  margin: 0 auto;
}
.top-border {
  border-top: 1px solid #d7d5d5;
}

.input-height-default {
  height: 50px;
}

.form-group {
  margin-bottom: 1px;
  display: flex;
  align-items: center;

  border-bottom: 1px solid #d7d5d5;
  border-left: 0;
  border-right: 0;
}

.addressName-input {
  max-width: 200px;
}
.recipient-input {
  max-width: 200px;
}

.address-input {
  max-width: 650px;
}
.address-detail-input {
  max-width: 650px;
}

.phone-number-input {
  max-width: 100px;
  height: 30px;
}

.form-group label {
  width: 150px;
  height: 48px;

  font-size: 18px;

  padding: 10px;
  background-color: #fbfafa;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form-group > label > span {
  display: inline-flex; /* Ensure the span itself is a flex container */
  align-items: center; /* Ensure the content of the span is centered vertically */
  margin-left: 5px; /* Optional: add some space between the text and asterisk */
}

.address-form label {
  height: 170px;
}

.form-group input,
.form-group select {
  flex: 1;
  padding: 5px;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #ccc; /* 기본 border 색상 */
}

.form-group input:focus,
.form-group select:focus {
  border-color: #007bff; /* 포커스 시 border 색상 */
}

.required {
  color: red;
}

.address-input-container {
  width: 80%;
  gap: 20px;

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.zipcode-input-box {
  width: 100%;
  display: flex;
  align-items: center;
}

.zipcode-input-box input {
  flex: 1;
  margin-right: 10px;

  max-width: 150px;
}

.zipcode-input-box button {
  padding: 5px 10px;
}

.phone-input {
  display: flex;
  align-items: center;
}

.phone-input select,
.phone-input input {
  margin-right: 5px;
}

.checkbox-group {
  height: 50px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; /* 요소들을 오른쪽으로 정렬 */

  margin-bottom: 5px;

  border-bottom: 1px solid #d7d5d5;
  border-left: 0;
  border-right: 0;
}

.checkbox-group input {
  margin-right: 5px;
}

.form-actions {
  padding: 20px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.form-actions button,
.form-actions a {
  width: 100px;
  height: 40px;

  margin-left: 10px;
  padding: 5px;

  background-color: #007bff;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.form-actions a {
  background-color: #6c757d;
}

.form-actions button:hover,
.form-actions a:hover {
  background-color: #0056b3;
}

.form-actions a:hover {
  background-color: #5a6268;
}

.address-notes {
  margin-top: 20px;
}

.address-notes h3 {
  margin-bottom: 10px;
}

.address-notes ol {
  list-style-type: decimal;
  padding-left: 20px;
}

.address-notes li {
  margin-bottom: 5px;
}

.ec-base-help {
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ec-base-help h3 {
  padding: 10px;
}

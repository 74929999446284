.product-slide {
    width: 100%;
    height: 780px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 120px;
}

.small-title {
    width: 70%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.product-slide-container {
    width: 100%;
    /* background-color: rgb(34, 34, 34); */
    padding-top: 30px;
    padding-bottom: 10px;
}

.product-slide-container>.swiper {
    width: 70%;
    height: 540px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: rgb(255, 255, 255);
    --swiper-theme-color: rgb(34, 34, 34);
}

/* .swiper-pagination-bullet {
    background: var(--swiper-pagination-bullet-inactive-color, rgb(230, 230, 230))
} */

.product-slide-container>.swiper>.swiper-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.product-slide-container>.swiper>.swiper-button-prev {
    display: none;
}

.product-slide-container>.swiper>.swiper-button-next{
    display: none;
}

.product-slide-container>.swiper-button-prev {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-90%);
    /* color: rgb(255, 255, 255); */
    color: rgb(34, 34, 34);
}

.product-slide-container>.swiper-button-next {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translateY(-90%);
    /* color: rgb(255, 255, 255); */
    color: rgb(34, 34, 34);
}

.product-slide-container>.swiper>.swiper-wrapper>.swiper-slide {
    width: 340px;
    height: 480px;
    cursor: pointer;
}

@-webkit-keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 0.15);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.15);
    }
}

@keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 0.15);
    }

    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }

    100% {
        background-color: rgba(165, 165, 165, 0.15);
    }
}

.swiper-slide-skeleton {
    -webkit-animation: skeleton-gradient 1.8s infinite ease-in-out;
    animation: skeleton-gradient 1.8s infinite ease-in-out;
}

.product-slide-card-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.product-card-img {
    width: 340px;
    height: 480px;
    background-color: rgb(210, 210, 210);
    object-position: center;
    object-fit: cover;
    border-radius: 18px;
}

.product-small-info {
    width: 100%;
    height: 120px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    bottom: 0px;
    background-color: rgba(34, 34, 34, 0.5);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    padding: 13px;
}

.pd-name-discount-recommend-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
}

.pd-name-discount-recommend-wrapper > a {
    /* color: rgb(190, 190, 190); */
    color: rgb(222, 189, 255);
    font-size: 18px;
}

.pd-name-discount-recommend-wrapper > a:first-child {
    color: rgb(255, 255, 255);
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 7px;
}

.product-small-info>a:last-child {
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: bold;
    margin: 15px 0px 5px 0px;
}

.link-btn {
    width: 240px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(34, 34, 34);
    border: 1px solid rgba(177, 177, 177, 1);
    border-radius: 5px;
    margin-top: 30px;
}

@media (max-width: 1200px) {
    .product-slide {
        margin-top: 90px;
    }

    .small-title {
        width: 90%;
    }

    .product-slide-container>.swiper {
        width: 90%;
    }

    .product-slide-container>.swiper-button-prev {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: -25px;
        transform: translateY(-50%);
        color: rgb(255, 255, 255);
    }
    
    .product-slide-container>.swiper-button-next {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        right: -25px;
        transform: translateY(-50%);
        color: rgb(255, 255, 255);
    }

    .product-small-info {
        width: 100%;
        padding: 10px;
    }
}

@media (max-width: 700px) {
    .product-slide-container {
        padding-bottom: 0;
    }

    .product-slide-container>.swiper {
        width: 340px;
    }

    .product-small-info {
        width: 100%;
        padding: 10px 20px;
    }
}
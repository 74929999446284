.admin-page-contents-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.admin-page-pd-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.admin-page-pd-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    margin-top: 30px;
    padding-bottom: 30px;
}

.admin-page-pd-img-container {
    width: 164px;
    height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(210, 210, 210);
}

.admin-page-pd-img-container>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.admin-page-pd-info-container {
    width: 65%;
    height: 164px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
}

.admin-page-pd-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.admin-page-pd-info>span:first-child {
    font-size: 18px;
    font-weight: bold;
}

.admin-page-pd-info>span {
    margin-bottom: 8px;
}

.is-discount-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.is-discount-info>span:first-child {
    margin-right: 15px;
}

.pd-created-info-container {
    width: 13%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.admin-page-pd-btn-container {
    width: 17%;
    height: 180px;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
}

.admin-page-pd-btn {
    width: 100px;
    height: 35px;
    border-radius: 25px;
    border: none;
    font-size: 14px;
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
    cursor: pointer;
    margin-bottom: 10px;
}

.admin-page-pd-btn>a {
    color: rgb(255, 255, 255);
}

.admin-paging-btn-container {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.admin-page-move-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
}

.admin-page-move-btn.prev-btn-disable,
.admin-page-move-btn.next-btn-disable {
    color: rgb(210, 210, 210);
}

.admin-page-move-btn:first-child {
    margin-right: 20px;
}

.admin-page-move-btn:last-child {
    margin-left: 20px;
}

.admin-page-num-btn {
    width: 20px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
    margin: 0px 5px;
}

.admin-page-num-btn:hover {
    font-size: 17px;
    font-weight: bold;
}

.admin-page-num-btn.admin-current-page {
    font-size: 17px;
    font-weight: bold;
}

.admin-page-contents-container>.confirm-modal {
    width: 30%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
    border-radius: 8px;
    padding: 15px;
}

.confirm-modal-content {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
}

.confirm-modal-content>p {
    font-size: 21px;
}

.confirm-modal-content>button {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1.5px solid rgb(210, 210, 210);
    outline: none;
    font-size: 16px;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}
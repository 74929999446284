.my-address-list-modal {
  width: 50%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
  visibility: hidden;
  opacity: 0;
  z-index: 1000;
}

.my-address-list-modal.address-modal-open {
  visibility: visible;
  opacity: 1;
}

.no-scroll {
  overflow: hidden;
}

.no-pointer-events {
  pointer-events: none;
}

/* .address-modal-blur {
  background: rgba(255, 255, 255, 0.8);
  filter: blur(1px) brightness(0.9);
} */

.address-list-modal-close {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  cursor: pointer;
}

.my-address-list-modal-top {
  min-height: 30px;
  text-align: start;
}

.my-address-list-modal-title {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.my-address-list-modal-title h2 {
  display: inline;

  font-size: 25px;
  font-weight: bold;

  padding: 5px;
  border-bottom: 2px solid black;
}

.my-address-list-modal-top p {
  display: inline;

  font-size: 15px;
}

/* Address Container */
.my-address-list-container {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-x: auto;
}

/* 주소록 테이블 */
.ec-base-table {
  width: 100%;

  position: relative;
  margin: 10px 0 0;
  border: 1px solid #d7d5d5;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #000000;
  line-height: 1.5;

  border-collapse: collapse;
}

.table-row {
  min-height: 64.05px;
  height: 64.05px;
  max-height: 71px;
  width: 100%;
}

.table-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 64.05px;
  height: 90%;
  max-height: 71px;

  overflow-y: auto; /* y 축 스크롤 추가 */
}

.ec-base-table th,
.ec-base-table td {
  padding: 10px;
  text-align: center;
  border: none;
}

.ec-base-table th {
  background-color: #f8f8f8;
}

.addrDefault {
  display: block;
  margin: 0 auto;
}

.address-table-header {
  width: 100%;
}

.address-list-header {
  width: 100%;
}

.address-list-header > tr {
  width: 100%;
}

.address-list-header > tr > th {
  font-size: 15px;
}

.total-payhis-count {
  margin-left: 10px;
  font-size: 14px;
  color: #666;
}

.address-registration-btn {
  display: inline-block;
  margin: 10px 0;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.message {
  text-align: center;
  padding: 20px;
  color: #999;
}

.button-box {
  justify-content: space-between !important;
}

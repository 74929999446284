.payment-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.payment-page-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
}

.payment-page-wrapper {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding-top: 30px;
}

.order-section {
    width: 60%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.order-section>div {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1.5px solid rgb(210, 210, 210);
}

.order-section>div>header {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}

.address-list-btn {
    border: 1.5px solid rgb(170, 170, 170);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    margin-left: 30px;
    transition: 0.1s ease;
}

.address-list-btn:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
    cursor: pointer;
}

.address-list-btn.address-list-btn-clicked {
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
}

.payment-page>.payment-page-wrapper>.order-section>.delivery>.my-address-list-page {
    width: 100%;
    padding-top: 0px;
}

.payment-page>.payment-page-wrapper>.order-section>.delivery>.my-address-list-page>.address-registration-form>.checkbox-group {
    border-bottom: none;
}

.inputWrap-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.inputWrap-name > input {
    padding: 13px 15px;
    border-radius: 5px;
    font-size: 1.0rem;
    width: 49%;
    height: 50px;
    outline-style: none;
    border: 1.5px solid rgb(170, 170, 170);
}

.inputWrap-address {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.inputWrap-address > input {
    padding: 13px 15px;
    border-radius: 5px;
    font-size: 1.0rem;
    width: 100%;
    height: 50px;
    outline-style: none;
    border: 1.5px solid rgb(170, 170, 170);
    margin-top: 30px;
}

.inputWrap-phone-email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.inputWrap-phone-email > input {
    padding: 13px 15px;
    border-radius: 5px;
    font-size: 1.0rem;
    width: 49%;
    height: 50px;
    outline-style: none;
    border: 1.5px solid rgb(170, 170, 170);
    margin-top: 30px;
}

.button-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.save-button {
    border-radius: 5px;
    border-style: none;
    font-size: 16px;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    cursor: pointer;
    transition: 0.3s;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.payment-option {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.payment-option-select {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option-pay-method {
    width: 20%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 15px;
    border-radius: 5px;
    font-size: 1.0rem;
    outline-style: none;
    border: 1.5px solid rgb(210, 210, 210);
    background-color: rgb(255, 255, 255);
    color: rgb(34, 34, 34);
    transition: 0.1s ease;
    cursor: pointer;
    margin-top: 30px;
}

.option-pay-method:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
}

.option-pay-method.selected-pay-method {
    color: rgb(255, 255, 255);
    background-color: rgb(34, 34, 34);
}

.cart-section {
    width: 25%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    position: sticky;
    top: 60px;
    overflow-y: auto;
}

.cart-title {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
}

.cart-info {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.cart-price,
.cart-delivery-fee,
.cart-total-price {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-price {
    padding-bottom: 10px;
}

.cart-delivery-fee {
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding-bottom: 15px;
}

.cart-total-price {
    font-weight: bold;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 15px 0px;
}

.cart-product {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding-bottom: 10px;
    margin-top: 30px;
}

.cart-product-info {
    width: 80%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 30px;
}

.cart-product-info>span {
    color: rgb(150, 150, 150);
}

.cart-product-info>span:nth-child(1) {
    font-size: 17px;
    font-weight: bold;
    color: rgb(34, 34, 34);
    margin-bottom: 10px;
}


.cart-product>img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    margin: 5px 0px 0px 5px;
}




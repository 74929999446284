.my-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.my-page-navigator-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-page-navigator {
  min-width: 300px;
  width: 30%;
  max-width: 400px;

  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-page-navigator > span > a {
  font-size: 17px;
  color: rgb(34, 34, 34);
  cursor: pointer;
}

.my-page-navigator > span {
  margin-left: 2px;
}

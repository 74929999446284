.my-wish-list-page {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.my-wish-list-page-title {
  width: 70%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.my-wish-list-page-title > span {
  font-size: 25px;
  font-weight: bold;
}

.my-wish-list-container {
  width: 70%;
  min-width: 740px;
  max-width: 760px;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  margin-top: 10px;
}

.my-wish-product-card {
  width: 360px;
  height: 480px;

  display: flex;
  flex-flow: column;

  justify-content: flex-start;
  align-items: center;
  margin: 10px 5px;
}

.my-wish-product-card > a > img {
  width: 306px;
  height: 432px;

  object-fit: cover;
  object-position: center;

  background-color: rgb(210, 210, 210);
  border-radius: 18px;
}

.my-wish-product-info {
  width: 306px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
}

.pd-name-type {
  width: 80%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.pd-name-type span {
  font-size: 17px;
  /* font-weight: bold; */
}

.my-wishlist-heart-btn {
  margin-right: 4px;
}

.article-submit-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(35, 35, 35, 0.8);
    overflow-y: auto;
}

.review-writing-modal,
.inquiry-writing-modal {
    width: 60%;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
}

.review-writing-modal.confirm-modal-active {
    filter: blur(3px) brightness(0.3);
}

.inquiry-writing-modal.confirm-modal-active {
    filter: blur(3px) brightness(0.3);
}

.modal-top-section {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 60px 0px 60px;
}

.review-writing-modal-product-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
}

.review-writing-modal-img-info {
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
}

.close-review-writing-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 25px;
}

.modal-content-section {
    width: 100%;
    padding: 0px 60px 60px 60px;
}

.review-option-select {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.review-option-select>span {
    margin-bottom: 10px;
}

.review-rate {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.review-rate>span {
    margin-bottom: 10px;
}

.review-rate-input>span {
    font-size: 40px;
}

.review-btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0px 30px 0px;
}

.writer-info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.first-label {
    margin-bottom: 10px;
}

.last-label {
    margin: 30px 0px 10px 0px;
}

.input-cover {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid rgb(99, 99, 99);
    border-radius: 8px;
    padding: 10px;
}

.input-cover>input {
    width: 100%;
    height: 100%;
    border-width: 0px;
    border-radius: 8px;
    outline: none;
    font-size: 16px;
}

.inquiry-writing {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.content-input-cover {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid rgb(99, 99, 99);
    border-radius: 8px;
    padding: 10px;
}

.title-input-cover {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.5px solid rgb(99, 99, 99);
    border-radius: 8px;
    padding: 10px;
}

.content-input-cover>textarea {
    width: 100%;
    height: 100%;
    border-width: 0px;
    border-radius: 8px;
    outline: none;
    resize: none;
    font-size: 16px;
}

.title-input-cover>input {
    width: 100%;
    height: 100%;
    border-width: 0px;
    border-radius: 8px;
    outline: none;
    resize: none;
    font-size: 16px;
}

.inquiry-category-select {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1.5px solid rgb(210, 210, 210);
    padding: 30px 0px;
}

.category-button-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category-button-box>button {
    width: 16%;
    height: 60px;
    border-radius: 8px;
    outline: none;
    border: 1.5px solid rgb(99, 99, 99);
    font-size: 16px;
    background-color: rgb(255, 255, 255);
    transition: background-color 0.3s ease;
}

.category-button-box>button:hover {
    cursor: pointer;
}

.category-button-box > button.active {
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
}

.additional-info {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 30px 0px;
}

.inquiry-btn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.submit-btn {
    width: 40%;
    height: 60px;
    border-radius: 8px;
    border: 1.5px solid rgb(210, 210, 210);
    outline: none;
    font-size: 16px;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.confirm-modal {
    width: 30%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0, 0, 0 / 7%) 0px 0px 6px 3px;
    border-radius: 8px;
    padding: 15px;
}

.confirm-modal-content {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
}

.confirm-modal-content>p {
    font-size: 21px;
}

.confirm-modal-content>button {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    border: 1.5px solid rgb(210, 210, 210);
    outline: none;
    font-size: 16px;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}
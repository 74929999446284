.detail-page {
    width: 70%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 60px;
}

.img-and-option-section {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 60px;
    border-bottom: 1px solid rgb(210, 210, 210);
}

.img-box {
    width: 600px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 60px;
    margin-right: 30px;
}

.thumbnail-img-container {
    width: 60px;
    height: 660px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.thumbnail-img {
    width: 100%;
    height: 60px;
    background-color: rgb(210, 210, 210);
    margin-bottom: 5px;
}

.thumbnail-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
}

.hero-img-container {
    width: 540px;
    height: 660px;
    display: flex;
    justify-content: flex-end;
}

.hero-img {
    width: 530px;
    height: 660px;
    background-color: rgb(210, 210, 210);
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

.option-and-info-box {
    width: 420px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
}

.option-container {
    width: 100%;
    height: 660px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}

.option-txt-box {
    width: 100%;
    height: 20%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.option-txt-box>li {
    margin-bottom: 5px;
}

.is-discount {
    font-size: 18px;
    color: rgb(204, 153, 255);
}

.product-name {
    font-size: 25px;
    font-weight: bold;
}

.product-type {
    font-size: 18px;
}

.product-price {
    font-size: 18px;
    margin-top: 15px;
}

.option-select-box {
    width: 100%;
    height: 50%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.option-select-box>div:nth-child(2), .option-select-box>div:nth-child(3) {
    margin-top: 30px;
}

.option-color-container {
    width: 100%;
    height: 23%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.option-color-container>ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.option-color-container>ul>li {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    border: 1.5px solid rgb(210, 210, 210);
    border-radius: 8px;
    cursor: pointer;
}

.option-color.selected-color {
    border: 1.8px solid rgb(34, 34, 34);
}

.option-size-container {
    width: 100%;
    height: 40%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.selected-color-null {
    width: 100%;
    height: 40%;
}

.option-size-container>ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.option-size-container>ul>li {
    width: 80px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    border: 1.5px solid rgb(210, 210, 210);
    border-radius: 8px;
    cursor: pointer;
}

.option-size.selected-size {
    border: 1.8px solid rgb(34, 34, 34);
}

.option-size.selected-size.unavailable {
    color: rgb(210, 210, 210);
    background-color: rgb(210, 210, 210);
}

.option-quantity-container {
    width: 100%;
    height: 19%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
}

.selected-size-null {
    width: 100%;
    height: 19%;
}

.quantity-input {
    width: 80px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1.5px solid rgb(210, 210, 210);
    border-radius: 8px;
}

.quantity-input>p {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-flex {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.btn-flex>svg {
    width: 30px;
    height: 50%;
}

.btn-flex>svg:hover {
    cursor: pointer;
}

.option-btn-box {
    width: 100%;
    height: 30%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
}

.option-btn-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
}

.option-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(34, 34, 34);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    font-size: 18px;
    margin-top: 15px;
    cursor: pointer;
}

.option-btn>svg {
    margin-right: 10px;
}

.option-dropdown-info {
    width: 100%;
    margin-top: 90px;
}

.option-dropdown-info-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
}

.option-dropdown-info-container>li {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1.5px solid rgb(210, 210, 210);
}

.option-info-title {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.option-dropdown-info-container>li:nth-child(1) {
    border-top: 1.5px solid rgb(210, 210, 210);
}

.option-info-title>p {
    font-size: 18px;
    font-weight: bold;
}

.option-info-title>svg {
    font-size: 30px;
}

.option-info-script {
    width: 100%;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.option-info-script.open {
    max-height: 1200px;
}

.article-link {
    margin-bottom: 70px;
}

.article-link>a {
    color: rgb(34, 34, 34);
    text-decoration: underline;
}

.detail-page-review-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 60px;
}

.rating-and-write-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
}

.rating-and-write-info>div {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-page-review-img {
    width: 90px;
    height: 90px;
    border: 1px solid rgb(34, 34, 34);
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
}

.detail-page-inquiry-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
}

.detail-page-inquiry-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.category-and-write-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.category-and-write-info>div {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 60px;
}

.detail-img-box {
    width: 100%;
    height: 100%;
}

.detail-img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.detail-img {
    width: 660px;
    height: 660px;
    margin: 0px 15px 5px 0px;
    background-color: rgb(210, 210, 210);
}

.detail-img>img {
    width: 660px;
    height: 660px;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1400px) {
    .detail-page {
        width: 90%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
    }
    
    .img-and-option-section {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 60px;
        border-bottom: 1px solid rgb(210, 210, 210);
    }
    
    .img-box {
        width: 480px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 30px;
        margin-right: 30px;
    }
    
    .thumbnail-img-container {
        width: 60px;
        height: 600px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .thumbnail-img {
        width: 100%;
        height: 60px;
        background-color: rgb(210, 210, 210);
        margin-bottom: 5px;
    }
    
    .thumbnail-img>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    }
    
    .hero-img-container {
        width: 420px;
        height: 600px;
        display: flex;
        justify-content: flex-end;
    }
    
    .hero-img {
        width: 410px;
        height: 600px;
        background-color: rgb(210, 210, 210);
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    .option-and-info-box {
        width: 420px;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 30px;
    }
    
    .option-container {
        width: 100%;
        height: 600px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-txt-box {
        width: 100%;
        height: 20%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-txt-box>li {
        margin-bottom: 5px;
    }
    
    .is-discount {
        font-size: 18px;
        color: rgb(204, 153, 255);
    }
    
    .product-name {
        font-size: 25px;
        font-weight: bold;
    }
    
    .product-type {
        font-size: 18px;
    }
    
    .product-price {
        font-size: 18px;
        margin-top: 15px;
    }
    
    .option-select-box {
        width: 100%;
        height: 50%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-select-box>div:nth-child(2), .option-select-box>div:nth-child(3) {
        margin-top: 30px;
    }
    
    .option-color-container {
        width: 100%;
        height: 23%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .option-color-container>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-color-container>ul>li {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
        cursor: pointer;
    }
    
    .option-color.selected-color {
        border: 1.8px solid rgb(34, 34, 34);
    }
    
    .option-size-container {
        width: 100%;
        height: 40%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .selected-color-null {
        width: 100%;
        height: 40%;
    }
    
    .option-size-container>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-size-container>ul>li {
        width: 80px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
        cursor: pointer;
    }
    
    .option-size.selected-size {
        border: 1.8px solid rgb(34, 34, 34);
    }
    
    .option-size.selected-size.unavailable {
        color: rgb(210, 210, 210);
        background-color: rgb(210, 210, 210);
    }
    
    .option-quantity-container {
        width: 100%;
        height: 19%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .selected-size-null {
        width: 100%;
        height: 19%;
    }
    
    .quantity-input {
        width: 80px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
    }
    
    .quantity-input>p {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex>svg {
        width: 30px;
        height: 50%;
    }
    
    .btn-flex>svg:hover {
        cursor: pointer;
    }
    
    .option-btn-box {
        width: 100%;
        height: 30%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-btn-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;
    }
    
    .option-btn {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(34, 34, 34);
        color: rgb(255, 255, 255);
        border-radius: 8px;
        font-size: 18px;
        margin-top: 15px;
        cursor: pointer;
    }
    
    .option-btn>svg {
        margin-right: 10px;
    }
    
    .option-dropdown-info {
        width: 100%;
        margin-top: 90px;
    }
    
    .option-dropdown-info-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-dropdown-info-container>li {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
    }
    
    .option-info-title {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-dropdown-info-container>li:nth-child(1) {
        border-top: 1.5px solid rgb(210, 210, 210);
    }
    
    .option-info-title>p {
        font-size: 18px;
        font-weight: bold;
    }
    
    .option-info-title>svg {
        font-size: 30px;
    }
    
    .option-info-script {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-info-script.open {
        max-height: 1200px;
    }
    
    .article-link {
        margin-bottom: 70px;
    }
    
    .article-link>a {
        color: rgb(34, 34, 34);
        text-decoration: underline;
    }
    
    .detail-page-review-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
    }
    
    .rating-and-write-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .rating-and-write-info>div {
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .detail-page-review-img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        margin-top: 10px;
    }
    
    .detail-page-inquiry-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
    }
    
    .detail-page-inquiry-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .category-and-write-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .category-and-write-info>div {
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .detail-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 60px;
    }
    
    .detail-img-box {
        width: 100%;
        height: 100%;
    }
    
    .detail-img-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    
    .detail-img {
        width: 660px;
        height: 660px;
        margin: 0px 15px 5px 0px;
        background-color: rgb(210, 210, 210);
    }
    
    .detail-img>img {
        width: 660px;
        height: 660px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

@media (max-width: 700px) {
    .detail-page {
        width: 90%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
    }
    
    .img-and-option-section {
        width: 100%;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
        border-bottom: 1px solid rgb(210, 210, 210);
    }
    
    .img-box {
        width: 100%;
        height: 545px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        position: static;
        margin-right: 0;
        margin-bottom: 30px;
    }
    
    .thumbnail-img-container {
        width: 100%;
        height: 60px;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
    }
    
    .thumbnail-img {
        width: 60px;
        height: 60px;
        background-color: rgb(210, 210, 210);
        margin-right: 5px;
    }
    
    .thumbnail-img>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
    }
    
    .hero-img-container {
        width: 100%;
        height: 480px;
        display: flex;
        justify-content: flex-end;
    }
    
    .hero-img {
        width: 100%;
        height: 100%;
        background-color: rgb(210, 210, 210);
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
    
    .option-and-info-box {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0;
    }
    
    .option-container {
        width: 100%;
        height: 600px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-txt-box {
        width: 100%;
        height: 20%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-txt-box>li {
        margin-bottom: 5px;
    }
    
    .is-discount {
        font-size: 18px;
        color: rgb(204, 153, 255);
    }
    
    .product-name {
        font-size: 25px;
        font-weight: bold;
    }
    
    .product-type {
        font-size: 18px;
    }
    
    .product-price {
        font-size: 18px;
        margin-top: 15px;
    }
    
    .option-select-box {
        width: 100%;
        height: 50%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-select-box>div:nth-child(2), .option-select-box>div:nth-child(3) {
        margin-top: 30px;
    }
    
    .option-color-container {
        width: 100%;
        height: 23%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .option-color-container>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-color-container>ul>li {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
        cursor: pointer;
    }
    
    .option-color.selected-color {
        border: 1.8px solid rgb(34, 34, 34);
    }
    
    .option-size-container {
        width: 100%;
        height: 40%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .selected-color-null {
        width: 100%;
        height: 40%;
    }
    
    .option-size-container>ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-size-container>ul>li {
        width: 80px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2px;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
        cursor: pointer;
    }
    
    .option-size.selected-size {
        border: 1.8px solid rgb(34, 34, 34);
    }
    
    .option-size.selected-size.unavailable {
        color: rgb(210, 210, 210);
        background-color: rgb(210, 210, 210);
    }
    
    .option-quantity-container {
        width: 100%;
        height: 19%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
    }
    
    .selected-size-null {
        width: 100%;
        height: 19%;
    }
    
    .quantity-input {
        width: 80px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1.5px solid rgb(210, 210, 210);
        border-radius: 8px;
    }
    
    .quantity-input>p {
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex {
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    
    .btn-flex>svg {
        width: 30px;
        height: 50%;
    }
    
    .btn-flex>svg:hover {
        cursor: pointer;
    }
    
    .option-btn-box {
        width: 100%;
        height: 30%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
    }
    
    .option-btn-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: center;
    }
    
    .option-btn {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(34, 34, 34);
        color: rgb(255, 255, 255);
        border-radius: 8px;
        font-size: 18px;
        margin-top: 15px;
        cursor: pointer;
    }
    
    .option-btn>svg {
        margin-right: 10px;
    }
    
    .option-dropdown-info {
        width: 100%;
        margin-top: 90px;
    }
    
    .option-dropdown-info-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-dropdown-info-container>li {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1.5px solid rgb(210, 210, 210);
    }
    
    .option-info-title {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .option-dropdown-info-container>li:nth-child(1) {
        border-top: 1.5px solid rgb(210, 210, 210);
    }
    
    .option-info-title>p {
        font-size: 18px;
        font-weight: bold;
    }
    
    .option-info-title>svg {
        font-size: 30px;
    }
    
    .option-info-script {
        width: 100%;
        max-height: 0;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    
    .option-info-script.open {
        max-height: 1200px;
    }
    
    .article-link {
        margin-bottom: 70px;
    }
    
    .article-link>a {
        color: rgb(34, 34, 34);
        text-decoration: underline;
    }
    
    .detail-page-review-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
    }
    
    .rating-and-write-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .rating-and-write-info>div {
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .detail-page-review-img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        margin-top: 10px;
    }
    
    .detail-page-inquiry-container {
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 50px;
    }
    
    .detail-page-inquiry-title {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    
    .category-and-write-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .category-and-write-info>div {
        width: 35%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .detail-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 60px;
    }
    
    .detail-img-box {
        width: 100%;
        height: 100%;
    }
    
    .detail-img-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    
    .detail-img {
        width: 660px;
        height: 660px;
        margin: 0px 15px 5px 0px;
        background-color: rgb(210, 210, 210);
    }
    
    .detail-img>img {
        width: 660px;
        height: 660px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
* {
    margin: 0px;
    padding: 0px;
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
}

/* body::-webkit-scrollbar {
    display: none;
}

body {
    -ms-overflow-style: none;
} */

.contents-body {
    width: 100%;
    height: 100%;
}

.test-div {
    height: 300px;
}
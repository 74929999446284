.payment-detail-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.my-payment-history-page {
  width: 100%;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.payhis-page-title-container {
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: rgb(243, 243, 243); */
}

.payhis-page-title {
  width: 70%;
  height: 70px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.payhis-page-title > span {
  font-size: 25px;
  font-weight: bold;
}

.total-payhis-count {
  font-size: 20px;
}

.payhis-page-filter {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1.5px solid rgb(210, 210, 210);
}

.payhis-sort-box {
  width: 20%;
  position: relative;
}

.payhis-filter-box {
  width: 20%;
  position: relative;
}

.payment-histories {
  background-color: rgb(255, 255, 255);

  min-width: 540px;
  width: 60%;
  max-width: 600px;

  display: flex;
  flex-direction: column; /* 위 아래로 배열 */
  justify-content: space-between;
  align-items: center;

  margin: 30px 0; /* 위아래 마진 30px */
  padding: 30px;
  gap: 20px; /* 플렉스 자식들 간의 간격 */
  border-radius: 5px;

  /* Shadow properties */
  box-shadow: -1px -1px 1px rgba(199, 198, 198, 0.5),
    10px 10px 10px rgba(199, 198, 198, 0.7);
}

.histories-detail-container {
  background-color: rgb(255, 255, 255);

  min-width: 560px;
  width: 80%;
  max-width: 600px;

  display: flex;
  flex-direction: column; /* 위 아래로 배열 */
  justify-content: space-between;
  align-items: center;

  padding: 30px;
  gap: 20px; /* 플렉스 자식들 간의 간격 */
}

.order-group-title {
  width: 100%;
  height: 20px;

  display: flex;
  flex-direction: row; /* 수평 방향으로 배치 */
  justify-content: space-between; /* 아이템 간의 여백을 균등하게 */
  align-items: center; /* 수직 중앙 정렬 */
}

.payment-content-title {
  width: 100%;
  height: 20px;

  display: flex;
  flex-direction: row; /* 수평 방향으로 배치 */
  justify-content: space-between; /* 아이템 간의 여백을 균등하게 */
  align-items: center; /* 수직 중앙 정렬 */
}

.payment-content-title button {
  height: 30px;

  font-size: 16px;
  font-weight: bold;
}

.payment-history-container {
  width: 100%;
  height: 100%;
}

.payhis-container {
  width: 100%;

  display: flex;
  flex-direction: column; /* 위 아래로 배열 */
  justify-content: space-between;
  align-items: flex-start;

  margin: 10px 0; /* 위아래 마진 10px */
  padding: 30px;

  border: 2px solid rgb(218, 217, 217);
  border-radius: 8px;
}

.payhis-container div {
  margin-bottom: 5px;
}

.payhis-product-info-container {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: row; /* 수평 방향으로 배치 */
  justify-content: space-between; /* 아이템 간의 여백을 균등하게 */
  align-items: center; /* 수직 중앙 정렬 */
}

.payhis-product-img {
  width: 100px;
  height: 100px;
  object-fit: cover; /* 이미지가 영역에 맞게 잘리도록 설정 */

  border-radius: 3px;
}

.payhis-product-info {
  width: 80%;
  max-width: 350px;
  min-width: 280px;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 20px; /* 이미지와 정보 사이의 간격 설정 */
}

.weight-font17 {
  font-size: 17px;
  font-weight: bold;
}

.payhis-info-container {
  width: 100%;

  display: flex;
  flex-direction: row; /* 수평 배열 */
  justify-content: space-between; /* 요소들을 양쪽 끝에 배치 */
  align-items: flex-start;

  margin-bottom: 20px;
}

.price-cart-container {
  width: 100%; /* 전체 너비 사용 */
  height: 40px;

  display: flex;
  flex-direction: row; /* 수평 배열 */
  justify-content: space-between; /* 요소들을 양쪽 끝에 배치 */
  align-items: center;
}

.payhisSmallButton {
  font-family: "chaenii", sans-serif;

  width: 85px;
  height: 28px;

  border-radius: 5px;
  border: 1px solid rgb(208, 208, 208);
  /* border-style: none; */

  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);

  transition: 0.3s;
}

.payhisSmallButton:hover {
  background-color: rgb(215, 213, 213);
  color: black;
  cursor: pointer;
}

.payment-history-btn-container {
  width: 100%;

  display: flex;
  flex-direction: row; /* 수평 배열 */
  justify-content: space-between; /* 요소들을 양쪽 끝에 배치 */
  align-items: center;
}

.payment-history-btn-container button {
  width: 48%;
  height: 40px;

  font-size: 17px;
  font-weight: bold;
}

.payment-history-review-container {
  width: 100%;

  display: flex;
  flex-direction: row; /* 수평 배열 */
  justify-content: space-between; /* 요소들을 양쪽 끝에 배치 */
  align-items: center;
}

.payment-history-review-container button {
  width: 100%;
  height: 40px;

  font-size: 17px;
  font-weight: bold;

  color: white;
  background-color: rgb(0, 0, 0);
}

.payment-history-review-container button:hover {
  background-color: rgb(255, 255, 255);
  color: black;
  cursor: pointer;
}

.payment-history-cart-container {
  width: 100%;

  display: flex;
  flex-direction: row; /* 수평 배열 */

  justify-content: center;
  align-items: center;
}

.payment-history-cart-container button {
  width: 100%;
  height: 40px;

  font-size: 18px;
}

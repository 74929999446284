.swiper {
    width: 100%;
    height: 540px;
    background-color: rgb(34, 34, 34);
    --swiper-theme-color: rgb(255, 255, 255);
}

.swiper-button-prev {
    width: 100px;
    height: 100px;
    top: 50%;
    left: var(--swiper-navigation-sides-offset, 150px);
    transform: translateY(-30%);
}

.swiper-button-next {
    width: 100px;
    height: 100px;
    top: 50%;
    right: var(--swiper-navigation-sides-offset, 150px);
    transform: translateY(-30%);
}

.swiper-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
}

.swiper-wrapper>.swiper-slide {
    width: 360px;
    height: 480px;
    border-radius: 18px;
    /* -webkit-box-reflect: below 30px linear-gradient(transparent, rgba(0,0,0,0), rgba(0,0,0,0.3)); */
}

@-webkit-keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 1);
    }

    50% {
        background-color: rgba(165, 165, 165, 1);
    }

    100% {
        background-color: rgba(165, 165, 165, 1);
    }
}

@keyframes skeleton-gradient {
    0% {
        background-color: rgba(165, 165, 165, 1);
    }

    50% {
        background-color: rgba(165, 165, 165, 1);
    }

    100% {
        background-color: rgba(165, 165, 165, 1);
    }
}

.skeleton-carousel-img-card {
    -webkit-animation: skeleton-gradient 1.8s infinite ease-in-out;
    animation: skeleton-gradient 1.8s infinite ease-in-out;
}

.carousel-card-container {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 18px;
}

.carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 18px;
}

.carousel-text {
    width: 100%;
    height: 150px;
    display: flex;
    flex-flow: column;
    position: absolute;
    bottom: -0.5px;
    color: rgb(255, 255, 255);
    background-color: rgba(34, 34, 34, 0.5);
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    padding: 13px;
}

.carousel-text-pd-name {
    font-size: 30px;
    margin-bottom: 13px;
}

.carousel-text-pd-discount {
    font-size: 25px;
    color: rgb(222, 189, 255);
    margin-bottom: 8px;
}

.carousel-text-pd-recommend {
    font-size: 25px;
    color: rgb(222, 189, 255);
}

@media (max-width: 1200px) {
    .swiper-button-prev {
        left: var(--swiper-navigation-sides-offset, -28px);
    }
    
    .swiper-button-next {
        right: var(--swiper-navigation-sides-offset, -28px);
    }
}
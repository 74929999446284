/* React Daum Address API */
.review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}

.review-modal-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;

  min-width: 600px;
  width: 80%;
  max-width: 800px;

  min-height: 700px;
  height: 100%;
  max-height: 700px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: relative;
}

.review-modal-body {
  margin-top: 10px;

  width: 100%;
  height: 100%;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  position: relative;
  padding-top: 40px;
}

.review-close-button {
  width: 100%;
  height: 100%;
  max-height: 50px;

  position: absolute; /* Changed to absolute */
  top: 0px;
  right: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: none;
  border: none;

  z-index: 2; /* Ensure it stays on top */
}

.review-close-button span {
  font-size: 22px;
}

.review-close-button button {
  background: none; /* Remove background */
  border: none; /* Remove border */
  padding: 1px; /* Remove padding */
  cursor: pointer; /* Ensure pointer cursor */
  margin-right: 10px; /* Add margin to separate from the right edge */
}

.review-close-image {
  width: 20px;
  height: 20px;
}

.review-close-button > .payment-review-title > img {
  width: 50px;
  height: 50px;
}

.payment-review-title {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-image-preview {
  margin-left: 20px;
}

.review-modal {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.review-modal-content {
  background: #fff;
  padding: 40px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;
}

/* React Daum Address API - 끝 */

.review-product-label {
  width: 180px;
  height: 50px;

  font-size: 17px;

  padding: 10px;
  margin-right: 20px;
  background-color: #f1f1f1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rating-input {
  width: 200px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rating-star {
  cursor: pointer;
  font-size: 34px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-content-area {
  height: 200px;
}

.review-select-box {
  min-width: 600px;
  width: 100%;
  max-width: 800px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #d7d5d5;
  border-left: 0;
  border-right: 0;
}

.review-select-box select {
  flex-grow: 1; /* Allow select to take remaining space */
  padding-left: 30px;

  border: 1px solid #edebeb;
  border-radius: 8px;
  padding: 12px;

  cursor: pointer;
}

.review-select-box textarea {
  flex-grow: 1;
  padding: 10px;

  min-width: 200px;
  width: 68%;
  max-width: 620px;

  height: 100%;

  border: 1px solid rgb(222, 222, 222);
  border-radius: 8px;
}

/* 리뷰 이미지 컨테이너 */
.image-preview-container {
  height: 100px;

  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  gap: 10px; /* Optional: space between images */
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;

  border-radius: 8px;
  border: 1px solid rgb(193, 194, 219);
}

.review-write-btn {
  width: 100%;
  height: 50px;

  font-size: 19px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid rgb(208, 208, 208);

  color: white;
  background-color: rgb(0, 0, 0);

  margin-top: 20px;
  font-family: "chaenii", sans-serif;
  transition: 0.3s;

  cursor: pointer;
}
